import axiosIns from '@/libs/axios'
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from '@/store/AxiosCaller';

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getTransmissionLogs({ dispatch }, payload) {

      let url = await dispatch('resolveUrl', {
        url: `/central/${payload.transmissionID}/elastic/logs/transmission`,
        params: payload.filters
      })

      return axiosCall({
        url: url,
        method: 'get',
        treatResponse: resp => resp.data.data
      })
      
    },
    getTransmissionLogsPaginated(_, payload) {
      return axiosCall({
        url: `/central/${payload.transmission_id}/elastic/logs/paginate`,
        method: 'post',
        payload: {scroll_id: payload.scroll_id}
      })
    },
    async getTransmissionStats({ dispatch }, payload) {
      let url = await dispatch("resolveUrl", {
        url: `/central/${payload.transmission_id}/elastic/dashboard/transmission`,
        params: payload.filters,
      })
      return axiosCall({
        url: url,
        method: 'get',
      })
    }
  }
};