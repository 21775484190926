import axiosIns from "@/libs/axios";
import moment from "moment";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    userAction: null,
    currentWhitelistIP: null,
    currentManageSession: null,
    searchTypeGodManagement: null,
    userIdGodManagement: null,
  },
  getters: {
    getUserAction: (state) => {
      return state.userAction;
    },
    getCurrentWhitelistIP: (state) => {
      return state.currentWhitelistIP;
    },
    getCurrentManageSession: (state) => {
      return state.currentManageSession;
    },
    getSearchTypeGodManagement: (state) => {
      return state.searchTypeGodManagement;
    },
    getUserIdGodManagement: (state) => {
      return state.userIdGodManagement;
    }
  },
  mutations: {
    SET_USER_ACTION(state, data) {
      state.userAction = data;
    },
    SET_CURRENT_WHITELIST_IP(state, data) {
      state.currentWhitelistIP = data;
    },
    SET_CURRENT_MANAGE_SESSION(state, data){
      state.currentManageSession = data
    },
    SET_SEARCH_TYPE_GOD_MANAGEMENT(state, data) {
      state.searchTypeGodManagement = data
    },
    SET_USER_ID_GOD_MANAGEMENT(state, data) {
      state.userIdGodManagement = data
    }

  },
  actions: {
    setUserAction({ commit }, state) {
      commit("SET_USER_ACTION", state);
      return;
    },
    fetchUserInfo({ commit }) {
      let config = {
        headers: {
          "x-api-url": "/central/user",
          session: `Session ${localStorage.getItem('sessionKey')}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              commit("SET_LOGGED_USER_SESSION", resp.data.data);
              
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            return false;
          });
      });
    },
    editUser({ commit }, payload) {
      let action = this.getters.getUserAction;
      let lowerAction = action.toLowerCase();
      
      let config = {
        headers: {
          "x-api-url": `/central/user/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload.data[`newUser${action}`],
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              this.dispatch('fetchUserInfo')
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async getIPWhitelistByUserId({ commit, dispatch }, data = null) {
      let url =await dispatch("resolveFilterUrl", {
        url: `/user/ip_whitelist`,
        filters: data,
      })

      return axiosCall({
        url: url,
        method: 'get'
      })
      .then((resp)=>{
        let ipInfo = [];
        let total_items = resp.paginator.total_items;

        // Orgs Info normzalized data //
        resp.items.map(function(value, key) {
          ipInfo.push(value);
          ipInfo[key].status = value.active? "Active": "Inactive";
          ipInfo[key].created_at = moment(value.created_at).format("LLL");
        });

        ipInfo["totalItems"] = total_items;
        
        return ipInfo;
      })
    },
    async getUserActiveSessionsByUserId({ dispatch }, data = null) {
      let url = await dispatch("resolveFilterUrl", {
        url: `/user/session`,
        filters: data,
      })

      return axiosCall({
        url: url,
        method: 'get'
      })
      .then((resp)=>{
        let sessionInfo = [];
        let total_items = resp.paginator.total_items;

        // Orgs Info normzalized data //
        resp.items.map(function(value, key) {
          sessionInfo.push(value);
          sessionInfo[key].last_activity = moment(value.last_activity).format("LLL");

          sessionInfo[key].created_at = moment(value.created_at).format("LLL");
        });

        sessionInfo["totalItems"] = total_items;

        return sessionInfo;
      })
    },
    deleteIPById({ commit }) {
      let ip = this.getters.getCurrentWhitelistIP.id
      
      let config = {
        headers: {
          "x-api-url": `/user/ip_whitelist/${ip}`,
          session: `Session ${localStorage.getItem('sessionKey')}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              commit("SET_LOADING_STATE", false)
              resolve(resp)
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            commit("SET_LOADING_STATE", false)
            reject(error)
          });
      });
    },
    deleteSession(_ ) {
      let session = this.getters.getCurrentManageSession.code

      let config = {
        headers: {
          "x-api-url": `/user/session/${session}`,
          session: `Session ${localStorage.getItem('sessionKey')}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp)
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            return error;
          });
      });
    },
    getUserByUsername(_ , data) {
      let config = {
        headers: {
          "x-api-url": `/central/user/by/username?value=${data}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              resolve(resp.data.data.items);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getUserByFullname({ commit }, data) {
      let config = {
        headers: {
          "x-api-url": `/central/user/by/fullname?value=${data}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              resolve(resp.data.data.items);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    async fetchUserToManage({ getters, dispatch, commit }, data) {
      let userId = getters.getUserIdGodManagement
      let searchType = getters.getSearchTypeGodManagement

      let config = {
        headers: {
          "x-api-url":  await dispatch("resolveFilterUrl", { url:`/user/${searchType}/by/user/${userId}`, filters: data }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then(async (resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let total_items = resp.data.data.paginator.total_items;

              let manageInfo = []
              
              resp.data.data.items.map(function(value, key) {
                manageInfo.push(value);
                manageInfo[key].last_activity ? (manageInfo[key].last_activity = moment(value.last_activity).format("LLL")) : null ;
                manageInfo[key].active ? (manageInfo[key].status = value.active ? "Active" : "Inactive") : null ;
                manageInfo[key].created_at ? (manageInfo[key].created_at = moment(value.created_at).format("LLL")) : null ;
              });
              
              manageInfo['totalItems'] = await total_items

              resolve(manageInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    setUserToManage({ commit }, payload){
      commit('SET_SEARCH_TYPE_GOD_MANAGEMENT', payload.searchType)
      commit('SET_USER_ID_GOD_MANAGEMENT', payload.userId)
    },
    embedUserSession({ dispatch }, data, state = null) {
      if(state === false){
        dispatch('setDebugMode', { state: state })
      }else{
        dispatch('setDebugMode', {session: data.code, state: true})
      }
      return
    },
    getLoggedUserAgain(_){
      return axiosCall({
        url: "/central/user",
        method: 'get'
      })
      .then((resp)=>{
        let auxResp = resp

        let auxLoggedUser = {
          userId: null,
          fullname: null,
          username: null,
          email: null,
          ability: null,
          verified_email: null,
          verified_phone: null,
          created_at: null
        }

        auxLoggedUser.userId = auxResp.id
        auxLoggedUser.username = auxResp.username
        auxLoggedUser.fullname = auxResp.fullname
        auxLoggedUser.email = auxResp.email
        auxLoggedUser.ability = auxResp.enum_access_level
        auxLoggedUser.verified_email = auxResp.email_verified
        auxLoggedUser.verified_phone = auxResp.phone_verified
        auxLoggedUser.created_at = auxResp.created_at

        return  auxLoggedUser
      })
    }
  },
};
