import axiosIns from "@/libs/axios"
import moment from "moment"
import router from "@/router";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    currentService: null,
    currentServicePermission: null, 
    serviceAction: null,
    currentWebhook: null,
    currentServiceWebhook: null,
    webhookAction: null,
    transmissionServices: undefined
  },
  getters: {
    getCurrentService: state => {
      return state.currentService;
    },
    getCurrentServicePermission: state => {
      return state.currentServicePermission;
    },
    getServiceAction: state => {
      return state.serviceAction;
    },
    getWebhookAction: state => {
      return state.webhookAction
    },
    getCurrentWebhook: state => {
      return state.currentWebhook
    },
    getCurrentServiceWebhook: state => {
      return state.currentServiceWebhook
    },
    getTransmissionServices: state => {
      return state.transmissionServices
    }
  },
  mutations: {
    SET_CURRENT_SERVICE(state, payload) {
      state.currentService = payload;
    },
    SET_CURRENT_SERVICE_PERMISSION(state, payload) {
      state.currentServicePermission = payload;
    },
    SET_SERVICE_ACTION(state, payload) {
      state.serviceAction = payload;
    },
    SET_WEBHOOK_ACTION(state, payload) {
      state.webhookAction = payload
    },
    SET_CURRENT_WEBHOOK(state, payload){
      state.currentWebhook = payload
    },
    SET_CURRENT_SERVICE_WEBHOOK(state, payload) {
      state.currentServiceWebhook = payload
    },
    SET_TRANSMISSION_SERVICES(state, payload){
      state.transmissionServices = payload
    },
    INSERT_TRANSMISSION_IN_SERVICES(state, payload){
      if (Array.isArray(state.transmissionServices)){
        state.transmissionServices.push(payload)
      }
    },
    REMOVE_SERVICE_FROM_TRANSMISSION(state, id){
      if (Array.isArray(state.transmissionServices)){
        let idx = state.transmissionServices.findIndex(el => el.id == id)
        if (idx > -1){
          state.transmissionServices.splice(idx, 1)
        }
      }
    },
  },
  actions: {
    setCurrentService({ commit }, payload) {
      commit('SET_CURRENT_SERVICE', payload);
    },
    setCurrentServicePermission({ commit }, payload) {
      commit('SET_CURRENT_SERVICE_PERMISSION', payload);
    },
    setServiceAction({ commit }, payload) {
      commit('SET_SERVICE_ACTION', payload);
    },
    setWebhookAction({ commit }, payload) {
      commit('SET_WEBHOOK_ACTION', payload)
    },
    currentServiceWebhook({ commit }, payload){
      commit('SET_CURRENT_SERVICE_WEBHOOK', payload)
    },
    async fetchServicesByTransmissionGroup({ commit, dispatch }, data = null) {
      let transmissionGroup = data.transmissionID
      let config = {
        headers: {
          "x-api-url": await dispatch("resolveUrl", { url: `/${transmissionGroup}/transmission/service`, params: data }),
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              let serviceInfo = [];
  
              // Orgs Info normzalized data // 
              resp.data.data.map(function(value, key){
                serviceInfo.push(value)
                serviceInfo[key].created_at = (moment(value.created_at).format('LLL'))
                serviceInfo[key].updated_at = (moment(value.updated_at).format('LLL'))
              })

              serviceInfo['totalItems'] = Object.keys(resp.data.data).length
              commit('SET_TRANSMISSION_SERVICES', resp.data.data)
              resolve(serviceInfo);

            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addService({commit, getters} , payload){
      let data = {
        identifier: payload.data.identifier,
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/service`,
        method: 'post',
        payload: data,
        treatResponse: resp => resp.data
      })
      .then((resp)=>{
        commit('INSERT_TRANSMISSION_IN_SERVICES', resp.data)
        return resp
      })
    },
    deleteServiceById({commit} , payload){
      let transmissionGroup = payload.transmissionID 
      const id = payload.item.id

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service/${id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit('REMOVE_SERVICE_FROM_TRANSMISSION', payload.item.id)
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    serviceInfo(_ ){
      let service = this.getters.getCurrentService.id;
      router.push({ name: "service-info", params: { serviceId: service } });
    },
    getServicebyId(_ , payload) {
      let transmissionGroup = payload.transmissionID

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service/${payload.serviceId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let serviceInfo = [];
              
              let value = resp.data.data
              // Orgs Info normzalized data // 
              serviceInfo = value
              serviceInfo.created_at = (moment(value.created_at).format('LLL'))
              serviceInfo.updated_at = (moment(value.updated_at).format('LLL'))
          
              resolve(serviceInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getEventsByService(_, payload){

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/service_event/by/service/${payload.serviceId}`,
        method: 'get'
      }).then((resp)=>{
        let serviceInfo = [];
              
        resp.map((value, key) => {
          serviceInfo.push(value)
          serviceInfo[key].id = value.event.id
          serviceInfo[key].identifier = value.event.event_identifier
          serviceInfo[key].created_at = (moment(value.created_at).format('LLL'))
          serviceInfo[key].updated_at = (moment(value.updated_at).format('LLL'))
        })

        return(serviceInfo);
      })
    },
    changeCanReceive(_ , payload){
      let transmissionGroup = payload.transmissionID

      let postData = {
        data: {
          "new_value": !payload.item.can_receive,
        },
      };
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}/can_receive`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    changeCanSend(_ , payload){
      let transmissionGroup = payload.transmissionID
      let postData = {
        data: {
          new_value: !payload.item.can_send,
        },
      };
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}/can_send`, 
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    addServicePermission({ getters }, payload){
      let transmissionGroup = payload.transmissionID

      let service = getters.getCurrentService.id

      let postData = {
        data: {
          event: parseInt(payload.data.event),
          service: parseInt(service),
        },
      };
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service_event`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteServicePermission(_ , payload) {
      let transmissionGroup = payload.transmissionID
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service_event/${payload.item.event.id}/${payload.item.service.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getServicesByTransmissionGroup({commit, getters}, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/service`,
        method: 'get'
      }).then((resp)=>{
        let aux ={
          items: resp
        }
        commit('SET_TRANSMISSION_SERVICES', resp)
        return aux;
      })
    },
    editService({ getters }, payload){

      let transmissionGroup  = payload.transmissionID
      let service = getters.getCurrentService.id
      let action = getters.getServiceAction;
      let lowerAction = action.toLowerCase();
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service/${service}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let patchData = {
        data: {
          new_value: payload.data[`newService${action}`],
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", patchData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    changeServiceToken(_, payload){
      let transmissionGroup = payload.transmissionID
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/service/${payload.serviceId}/token`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    //WEBHOOK
    getAllWebhooksByService(_ , payload){
      let transmissionGroup = payload.transmissionID 

      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook/by/service/${payload.serviceId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let webhookInfo = [];
              
              resp.data.data.map((value, key) => {
                webhookInfo.push(value)
                webhookInfo[key].status = value.enum_webhook_status_id == "1" ? "Inactive" : "Active"
                webhookInfo[key].created_at = (moment(value.created_at).format('LLL'))
                webhookInfo[key].updated_at = (moment(value.updated_at).format('LLL')) 
              })

              webhookInfo['totalItems'] = webhookInfo.length
              
              resolve(webhookInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getWebhooksByTransmission(_ , transmissionID){
      let config = {
        headers: {
          "x-api-url": `/${transmissionID}/transmission/webhook`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              let webhookInfo = [];
              
              resp.data.data.items.map((value, key) => {
                webhookInfo.push(value)
                webhookInfo[key].status = value.enum_webhook_status_id == "1" ? "Inactive" : "Active"
                webhookInfo[key].created_at = (moment(value.created_at).format('LLL'))
                webhookInfo[key].updated_at = (moment(value.updated_at).format('LLL')) 
              })

              webhookInfo['totalItems'] = webhookInfo.length
              
              resolve(webhookInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    addServiceWebhook({ getters }, payload){
      let transmissionGroup = payload.transmissionID

      let service = getters.getCurrentService.id

      let postData = {
        data: {
          identifier: payload.data.newWebhookIdentifier,
          service_id: parseInt(service),
        },
      };
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    deleteWebhookById(_ , payload){
      let transmissionGroup = payload.transmissionID
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook/${payload.item.id}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .delete("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    webhookInfo(_, payload ){
      let webhook = this.getters.getCurrentServiceWebhook.id;
      router.push({ name: "webhook-info", params: { webhookId: webhook, ...payload } });
    },
    getWebhookById(_ , payload){
      let transmissionGroup = payload.transmissionID
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook/${payload.webhookId}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {

              let serviceInfo = [];
              
              let value = resp.data.data

              serviceInfo = value
              serviceInfo.created_at = (moment(value.created_at).format('LLL'))
              serviceInfo.updated_at = (moment(value.updated_at).format('LLL'))

              resolve(serviceInfo);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    editWebhook({ getters }, payload){
      let transmissionGroup  = payload.transmissionID
      let webhook = getters.getCurrentWebhook.id
      let action = getters.getWebhookAction;
      let lowerAction = action.toLowerCase();
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook/${webhook}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let patchData = {
        data: {
          new_value: payload.data[`newWebhook${action}`],
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", patchData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    fetchEnumStatus(_ ){
      let status = {
        items: {
          1: {
            id: 1,
            label: "webhook.status.inactive",
          },
          2: {
            id: 2,
            label: "webhook.status.active",
          },
        }
      }

      return status
    },
    changeWebhookToken(_, payload){
      let transmissionGroup = payload.transmissionID
      let config = {
        headers: {
          "x-api-url": `/${transmissionGroup}/transmission/webhook/${payload.webhookId}/token`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
  }
};