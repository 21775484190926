import axiosIns from "@/libs/axios";
import moment from 'moment'
import Vue from "vue";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    selectedPlan: null,
    selectedBaseServer: null,
    selectedSystemLogServer: null,
    selectedProcessedLogServer: null,
    selectedTransmissionGroup: null,
    currentGroup: null,
    groupAction: null,
    transmissions: {
      orgID: null,
      items: null,
    },
    schedules: {},
    variablesPannelVars: undefined,
  },
  getters: {
    getSelectedPlan: (state) => {
      return state.selectedPlan;
    },
    getSelectedBaseServer: (state) => {
      return state.selectedBaseServer;
    },
    getSelectedSystemLogServer: (state) => {
      return state.selectedSystemLogServer;
    },
    getSelectedProcessedLogServer: (state) => {
      return state.selectedProcessedLogServer;
    },
    getSelectedTransmissionGroup: (state) => {
      return state.selectedTransmissionGroup;
    },
    getCurrentGroup: (state) => {
      return state.currentGroup
    },
    getGroupAction: (state) => {
      return state.groupAction
    },
    getTransmissions: (state) => {
      return state.transmissions
    },
    getVariablesPannelVars: (state) => {
      return state.variablesPannelVars
    },
  },
  mutations: {
    SET_SELECTED_PLAN(state, payload) {
      state.selectedPlan = payload;
    },
    SET_SELECTED_BASE_SERVER(state, payload) {
      state.selectedBaseServer = payload;
    },
    SET_SELECTED_SYSTEM_LOG_SERVER(state, payload) {
      state.selectedSystemLogServer = payload;
    },
    SET_SELECTED_PROCESSED_LOG_SERVER(state, payload) {
      state.selectedProcessedLogServer = payload;
    },
    SET_SELECTED_TRANSMISSION_GROUP(state, payload) {
      state.selectedTransmissionGroup = payload;
    },
    SET_CURRENT_GROUP(state, payload) {
      state.currentGroup = payload
    },
    SET_GROUP_ACTION(state, payload) {
      state.groupAction = payload
    },
    SET_TRANSMISSIONS(state, payload) {
      state.transmissions.items = payload.items
      state.transmissions.orgID = payload.orgID
    },
    SET_VARIABLES_PANNEL(state, payload) {
      state.variablesPannelVars = payload
    },
    ADD_TRANSMISSION(state, payload) {
      state.transmissions.items.unshift(payload)
    },
    UPDATE_TRANSMISSION(state, payload) {
      let items = state.transmissions.items
      items.forEach((value, index) => {
        if(value.id == payload.id) {
          Vue.prototype.$set(items, [index], payload)
        }
      })
    },
    DELETE_TRANSMISSION(state, id) {
      let items = state.transmissions.items
      items.forEach((value, index) => {
        if(value.id == id) {
          items.splice(index, 1)
        }
      })
    }
  },
  actions: {
    setSelectedPlan({ commit }, payload) {
      commit("SET_SELECTED_PLAN", payload);
    },
    setSelectedBaseServer({ commit }, payload) {
      commit("SET_SELECTED_BASE_SERVER", payload);
    },
    setSelectedSystemLogServer({ commit }, payload) {
      commit("SET_SELECTED_SYSTEM_LOG_SERVER", payload);
    },
    setSelectedProcessedLogServer({ commit }, payload) {
      commit("SET_SELECTED_PROCESSED_LOG_SERVER", payload);
    },
    setSelectedTransmissionGroup({ commit }, payload) {
      commit('SET_SELECTED_TRANSMISSION_GROUP', payload);
    },
    setGroupAction({ commit }, payload) {
      commit("SET_GROUP_ACTION", payload)
    },
    createTransmissionGroup({ commit, getters, dispatch }, data) {
      let postData = {
        name: data.name,
        description: data.description ? data.description : "",
        organization: parseInt(
          localStorage.getItem('selectedOrganization')
        ),
        mysql_db: getters.getSelectedBaseServer.id,
        processed_log: getters.getSelectedProcessedLogServer.id,
        system_log: getters.getSelectedSystemLogServer.id,
      };

      return axiosCall({
        url: "/central/central_transmission",
        method: "post",
        payload: postData,
        treatResponse: resp => resp,
      })
      .then((resp) => {
        if(getters.getTransmissions.items) {
          commit('ADD_TRANSMISSION', resp.data.data)
        }
        
        dispatch("setPlanSubscription", {
          data: resp.data.data,
          plan: getters.getSelectedPlan,
        })
        .then((resp) => {
          commit("SET_LOADING_STATE", false);
          commit("SET_SELECTED_PLAN", null);
          commit("SET_SELECTED_BASE_SERVER", null);
          commit("SET_SELECTED_SYSTEM_LOG_SERVER", null);
          commit("SET_SELECTED_PROCESSED_LOG_SERVER", null);
          return resp
        })
        .catch((error) => {
          throw(error);
        });
        
        return resp;
      })
    },
    getTransmissionGroupById({ commit }, data) {
      let transmissionID = data.transmissionID;

      return axiosCall({
        url: `/central/central_transmission/${transmissionID}`,
        method: 'get'
      }).then((resp)=>{
        resp.created_at = moment(resp.created_at).format("LLL")
        resp.updated_at = moment(resp.updated_at).format("LLL")

        commit('SET_SELECTED_TRANSMISSION_GROUP', resp)
        return(resp);
      })
    },
    async getTransmissionByOrganization({ dispatch, commit }, payload ) {
      let orgId = localStorage.getItem('selectedOrganization');
      if (payload.organization){
        orgId = String(payload.organization)
      }
      let data = orgId ? { value: orgId, ...payload.filter } : resolve([]);

      let url = await dispatch("resolveUrl", {
        // url: `/central/central_transmission/by/organization?per_page=50`,
        url: `/central/central_transmission/by/organization`,
        params: data,
      })

      return axiosCall({
        url: url,
        method: 'get',
      }).then((resp)=>{
        let items = resp.items;
        items.map((value, key) => {
          items[key].created_at = moment(value.created_at).format('LL')
          items[key].updated_at = moment(value.updated_at).format('LL')
        })
        commit('SET_TRANSMISSIONS', { items, orgID: orgId });
        return(items);
      })
    },
    editTransmission({ commit, getters}, payload) {
      let groupId = payload.transmissionID
      let action = this.getters.getGroupAction;
      let lowerAction = action.toLowerCase();

      let config = {
        headers: {
          "x-api-url": `/central/central_transmission/${groupId}/${lowerAction}`,
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      let postData = {
        data: {
          new_value: payload.data[`newGroup${action}`],
        },
      };
      
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              
              if(getters.getTransmissions.items) {
                commit('UPDATE_TRANSMISSION', resp.data.data)
              }

              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            // commit('SET_IS_BTABLE_BUSY', false)
            reject(error);
          });
      });
    },
    getRequestLogs(_ , payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let query_id = "";
        if (payload.previous_id) {
          query_id = `?id=${payload.previous_id}`;
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/log/all${query_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            // commit('SET_IS_BTABLE_BUSY', false)
            reject(error);
          });
      });
    },
    getRequestOccurrencesLogs(_ , payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/log/token/${payload.token}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            // commit('SET_IS_BTABLE_BUSY', false)
            reject(error);
          });
      });
    },
    deleteTransmissionById({ getters, commit }, id) {
      return axiosCall({
        url: `/central/central_transmission/${id}`,
        method: 'delete',
      }).then((resp)=>{
        if(getters.getTransmissions.items) {
          commit('DELETE_TRANSMISSION', id)
          return resp
        }
      })
    },
    exportWebhookByIDs(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }
        
        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/export_webhooks/by/id`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        const postData = {
          "data": {
            "webhooks": payload.id_list
          }
        }

       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    importScript(_, payload) {
      return new Promise((resolve, reject) => {
        const transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        const _env = localStorage.getItem(`currentEnv-${transmissionGroup}`)
        
        if (!_env) {
          reject({ "message": "common.error.env_not_selected" })
        }

        const env = JSON.parse(_env);
        
        let overwrite = "";
        if(payload.overwrite) {
          overwrite = "w=true";
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/import/${env.id}?${overwrite}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

        const postData = {
          "data": {
            "script": payload.script
          }
        }

       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      })
    },
    sendScriptTest(_, payload) {
      let env = JSON.parse(localStorage.getItem(`currentEnv-${payload.transmissionID}`));
      let data = {
        "event_id": payload.eventID,
        "sender_id": payload.senderID,
        "receiver_id": payload.receiverID,
        "data": payload.data,
      }
      return axiosCall({
        url: `/central/${payload.transmissionID}/script/test/${env.id}/event`,
        method: 'post',
        payload: data,
      })
    },
    getPanelVars({commit}, payload) {
      const data = {
        "categories": payload.categories
      }
      
      return axiosCall({
        url: `/${payload.transmission_id}/transmission/variable/by/category`,
        method: 'post',
        payload: data
      })
      .then((resp)=>{
        commit('SET_VARIABLES_PANNEL', resp)
        return resp;
      })
    },
    getTransmissionOverview({commit}, transmissionID){
      return axiosCall({
        url: `/${transmissionID}/central_transmission/overview`,
        method: "get",
      })
    }
  },
};
