import Vue from 'vue';
import { axiosCall } from '@/store/AxiosCaller';

export default {
  namespaced: true,
  state: {
    services_by_org: {},
    services_categories: {},
    installed_services: {},
    service_info: {},
    service_comments: {},
    favorited_services: {}
  },
  getters: {
    getServicesByOrg: (state) => (id) => {
      if (!state.services_by_org[id]) return
      
      return state.services_by_org[id].services;
    },
    getOrgServicesInfo: (state) => (id) => {
      if (!state.services_by_org[id]) return

      return state.services_by_org[id].org;
    },
    getIntegratorServiceByID: (state) => (orgID, serviceID, pos = null) => {
      if (!state.services_by_org[orgID]) return;
      let idx = state.services_by_org[orgID].services.findIndex((el) => el.id == serviceID);
      
      if (idx < 0) return {};
      
      return pos ? state.services_by_org[orgID].services[idx][pos] : state.services_by_org[orgID].services[idx];
    },
    getIntegratorServiceCategories: (state) => {
      return state.services_categories;
    },
    getInstalledServices: (state) => {
      return state.installed_services;
    },
    getServiceInfo: (state) =>{
      return state.service_info;
    },
    getServiceComments: (state) => {
      return state.service_comments;
    },
    getFavoritedServices: (state) => {
      return state.favorited_services
    }
  },
  mutations: {
    INSERT_SERVICE_BY_ORG(state, payload) {
      Vue.set(state.services_by_org, payload.orgID, payload.data)
    },
    INSERT_SERVICE_CATEGORIES(state, payload) {
      state.services_categories = payload
    },
    INSERT_SERVICE(state, payload) {
      if (!state.services_by_org[payload.orgID]) return

      state.services_by_org[payload.orgID].services.unshift(payload.data)
    },
    INSERT_SERVICE_CATEGORY(state, payload){
      if (Array.isArray(state.services_categories)){
        state.services_categories.push(payload)
      }
    },
    INSERT_SERVICE_COMMENT(state, payload){
      if (state.service_comments.length == 0){
        state.service_comments = []
      }
      state.service_comments.unshift(payload.data)
    },    
    INSERT_INSTALLED_SERVICE(state, payload){
      const {releaseID} = payload

      state.service_info.installed_releases.push(releaseID)
    },
    PATCH_INTEGRATOR_SERVICE(state, payload) {
      let { orgID, serviceID, data } = payload
      
      if (!state.services_by_org[orgID]) return;
      
      let idx = state.services_by_org[orgID].services.findIndex((el) => el.id == serviceID);

      if (!idx) return {};

      Vue.set(state.services_by_org[orgID].services, idx, data)
    },
    PATCH_INTEGRATOR_SERVICE_PARAM(state, payload) {
      let { orgID, serviceID, new_value, param } = payload
      
      if (!state.services_by_org[orgID]) return;
      
      let idx = state.services_by_org[orgID].services.findIndex((el) => el.id == serviceID);

      if (!idx) return {};

      let service = state.services_by_org[orgID].services[idx]

      Vue.set(state.services_by_org[orgID].services[idx], param, new_value)
    },
    PUT_INSTALLED_SERVICES(state, { data }){
      data.sort((el) => {
        return el.favorite ? -1 : 1
      })

      state.installed_services = data
    },
    PUT_SERVICE_INFO(state, { data }){
      state.service_info = data
    },    
    PUT_SERVICE_COMMENTS(state, { data }) {
      state.service_comments = data;
    },
    PUT_FAVORITED_SERVICES(state, { data }) {
      state.favorited_services = data;
    },
    CLEAR_SERVICE_INFO(state){
      state.service_info = {}
    },
    TOGGLE_FAVORITE_SERVICE(state, payload) {
      const { data, id } = payload
      
      state.service_info.favorite = !state.service_info.favorite

      if (Array.isArray(state.favorited_services)){
        if(data){
          if (!data.integrator_service.average_rating){
            data.integrator_service.average_rating = 0
          }
          state.favorited_services.push(data.integrator_service)
        } else {
          let idx = state.favorited_services.findIndex(service => service.id == id)
          if (idx > -1) {
            state.favorited_services.splice(idx, 1)
          }
        }
      }
    },
    REMOVE_INSTALLED_SERVICE(state, { releaseID }){

      if (state.service_info.installed_releases){
        let idx = state.service_info.installed_releases.findIndex(id => {
          return id == releaseID
        });
        if (idx > -1){
          state.service_info.installed_releases.splice(idx,1)
        }
      }

      if (!Array.isArray(state.installed_services)) return
      
      let idx = state.installed_services.findIndex(service => {
        return service.release.id == releaseID
      });
      if (idx == -1) return

      state.installed_services.splice(idx, 1);
    }
    
  },
  actions: {
    clearIntegratorServiceInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_SERVICE_INFO', {});        
      });
    },
    fetchIntegratorServicesByOrganization({ commit, state }, {organizationID, currentOrgID}) {
      if (state.services_by_org[organizationID]) {
        return 
      }

      let url
      if (currentOrgID){
        url = `/module/integrator_service/by/organization/${organizationID}?current_org_id=${currentOrgID}`
      } else {
        url = `/module/integrator_service/by/organization/${organizationID}`
      }
      
      return axiosCall({
        url: url,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        commit('INSERT_SERVICE_BY_ORG', { orgID: organizationID, data: resp.data.data })
        return { orgID: organizationID, data: resp.data.data };
      })
    },
    fetchInstalledServices({ commit, state }, { organizationID }) {
      return axiosCall({
        url: `/module/integrator_library/by/org/${organizationID}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        commit('PUT_INSTALLED_SERVICES', { data: resp.data.data })     
        return {data: resp.data.data };
      })
    },
    fetchFavoritedServicesByOrg({ commit, state }, {organizationID}) {
      return axiosCall({
        url: `/module/integrator_favorite/by/organization/${organizationID}`,
        method: 'get',
        treatResponse: resp => resp
      })
      .then((resp)=>{
        commit('PUT_FAVORITED_SERVICES', { data: resp.data.data })
        return {data: resp.data.data };
      })
    },
    searchIntegratorServices({ commit, state }, {queries}) {
      function serialize(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
      }
      let q = (serialize(queries))

      return axiosCall({
        url: `/module/integrator_service/public?${q}`,
        method: 'get',
      })
    },
    fetchServiceByIdentifier({ commit, state }, {organizationID , identifier}) {
      return axiosCall({
        url: `/module/integrator_service/identifier/${identifier}?org_id=${organizationID}`,
        method: 'get',
      })
      .then((resp=>{
        commit('PUT_SERVICE_INFO', { data: resp })
        return {data: resp};
      }))
    },
    fetchServiceComments({ commit }, {serviceID}){
      return axiosCall({
        url:  `/${serviceID}/module/integrator_service_rating/by/integrator_service`,
        method: 'get'
      })
      .then((resp)=>{
        commit('PUT_SERVICE_COMMENTS', {data: resp})
        return ;
      })
    },
    insertServiceComment({ commit }, payload) {
      let { serviceID, data } = payload;

      return axiosCall({
        url: `/${serviceID}/module/integrator_service_rating`,
        method: 'post',
        payload: data
      })
      .then((resp)=>{
        commit('INSERT_SERVICE_COMMENT', {data: resp})
        return resp;
      })
    },
    toggleFavorite({ commit, state }, {organizationID, integrator_service, operation}) {
      const data = {
        organization: parseInt(organizationID),
        integrator_service: parseInt(integrator_service)
      }
      if (operation=='put'){
        return axiosCall({
          url: `/module/integrator_favorite`,
          method: 'post',
          payload: data
        })
        .then((resp)=>{
          commit('TOGGLE_FAVORITE_SERVICE', { data: resp, id: integrator_service})
          return {data: resp };
        })

      } else if (operation=='remove'){

        return axiosCall({
          url: `/module/integrator_favorite/${organizationID}/${integrator_service}`,
          method: 'delete',
        })
        .then((resp)=>{
          commit('TOGGLE_FAVORITE_SERVICE', { data: resp, id: integrator_service})
          return {data: resp };
        })
      }
    },
    fetchIntegratorServiceCategories({ commit, state }) {
      
      // if (!Array.isArray(state.services_categories)) {
      //   return;
      // }

      return axiosCall({
        url: `/module/integrator_category`,
        method: 'get',
      })
      .then((resp)=>{
        commit('INSERT_SERVICE_CATEGORIES', resp)
        return resp
      })
    },
    installService({ commit, state }, {organizationID, releaseID}) {
      let data = {
        integrator_service_release: parseInt(releaseID),
        organization: parseInt(organizationID)
      }

      return axiosCall({
        url:  `/module/integrator_library`,
        method: 'post',
        payload: data
      })
      .then((resp)=>{
        commit('INSERT_INSTALLED_SERVICE', { releaseID: releaseID })
        return {data: resp };
      })
    },
    createIntegratorService({ commit }, payload) {
      let data = {
          integrator_category: parseInt(payload.category.id),
          organization: parseInt(payload.organizationID),
          name: payload.name,
          identifier: payload.identifier,
          host: payload.host,
          docs_uri: payload.docs_uri || '',
          short_description: payload.short_description,
          image_uri: payload.image_uri || '',
          cover_uri: payload.cover_uri || '',
          public: payload.privacy,
      };
      
      return axiosCall({
        url : `/module/integrator_service`,
        method: 'post',
        payload: data
      })
      .then((resp)=>{
        commit('INSERT_SERVICE', { data: resp, orgID: payload.organizationID })
        return resp;
      })
    },
    createIntegratorCategory({commit}, payload){

      let url = `/module/integrator_category`
      let newPayload = {
        label: payload.name
      }

      let r = axiosCall({
        method: 'post',
        url: url,
        payload: newPayload
      })
      r.then((resp)=>{
        commit('INSERT_SERVICE_CATEGORY', resp)
      })
      
      return r
    },
    fetchServiceBadges({ commit }, serviceID) {
      return new Promise((resolve, reject) => {
        resolve({ data: [] });
      })
    },
    updateIntegratorService({ commit }, payload) {
      return axiosCall({
        url: `/module/integrator_service/${payload.serviceID}`,
        method: 'put',
        payload: payload.data
      })
      .then((resp)=>{
        commit('PATCH_INTEGRATOR_SERVICE', { data: resp, serviceID: payload.serviceID, orgID: payload.orgID })
        return resp;
      })
    },
    patchIntegratorService({ commit }, payload) {
      return axiosCall({
        url: `/module/integrator_service/${payload.serviceID}/${payload.field}`,
        method: 'patch',
        payload: {new_value: payload.new_value}
      })
      .then((resp)=>{
        commit('PATCH_INTEGRATOR_SERVICE_PARAM', {
          param: payload.field,
          orgID: payload.orgID,
          serviceID: payload.serviceID,
          new_value: payload.new_value,
        });
        return resp;
      })
    },
    uninstallService({ commit, state }, {organizationID, releaseID}) {
      return axiosCall({
        url: `/module/integrator_library/${releaseID}/${organizationID}`,
        method: 'delete',
      })
      .then((resp)=>{
        commit('REMOVE_INSTALLED_SERVICE', { releaseID: releaseID })
        return {data: resp};
      })
    },
    
  }
};