import { axiosCall } from "../AxiosCaller";

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    saveAgents({dispatch}, payload) {
      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID
      let data = {
        agents: payload.agentList
      }
      return axiosCall({
        url: `/${transmissionGroup}/transmission/middleware_agent/bulk`,
        method: 'post',
        payload: data,
        treatResponse: resp => resp.data
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },
    insertAgent({dispatch}, payload) {
      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID
      
      //banana
      // you can use this to add an Agent on a certain Index 
      // payload.index = 1 
      //the index starts at '1'

      return axiosCall({
        url: `/${transmissionGroup}/transmission/middleware_agent`,
        method:'post',
        payload: payload,
        treatResponse: resp => resp.data
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },
    updateAgent({dispatch}, payload) {

      let transmissionGroup = payload.transmissionID
      delete payload.transmissionID

      return axiosCall({
        url: `/${transmissionGroup}/transmission/middleware_agent/${payload.id}`,
        method: 'put',
        payload: payload,
        treatResponse: resp => resp.data
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },
    deleteAgent(_, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/middleware_agent/${payload.id}`,
        method: 'delete',
        treatResponse: resp => resp.data
      })
    },
    changeAgentsExecutionOrderList(_, payload) {
      const data = { list: payload.list }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/middleware_agent/execution_order/list`,
        method: 'patch',
        payload: data,
        treatResponse: resp => resp.data
      })

    },

    // =========================== PROBABLY LEGACY ==========================
    getAgentByID(_, payload) {
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/middleware_agent/${payload.agentID}`,
        method: 'get'
      })
    },
    // =========================== PROBABLY LEGACY ==========================

    
  },
};