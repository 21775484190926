import axiosIns from "@/libs/axios";
import EventBus from "@/custom/eventBus";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    value: 'my value'
  },
  getters: {
    value: state => {
      return state.value;
    }
  },
  mutations: {
    updateValue(state, payload) {
      state.value = payload;
    }
  },
  actions: {
    async getAllEnvironmentsByTransmissionGroup({ dispatch, commit }, payload = null){

      let data = { 
        page: payload.page || null,
        per_page: payload.per_page || 10,
        order: "id DESC"
      }

      return axiosCall({
        url:  await dispatch('resolveFilterUrl', { url: `/${payload.transmissionID}/transmission/environment`, filters: data }),
        method: 'get'
      }).then((resp)=>{
        let environments = resp

        environments["links"] = resp.__links;
        environments["paginator"] = resp.paginator;

        return environments;
      })
    },
    getEnvironmentById({ commit }, payload){

      return axiosCall({
        url: `/${payload.transmissionID}/export_vars/${payload.envID}`,
        method: 'get'
      })
      .then((resp)=>{
        return(JSON.parse(resp.script));
      })
    },
    addEnvironment(_ , payload) {
      let postData = {
        name: payload.name ? `${payload.name}` : `Environment ${payload.index+1}`
      };

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment`,
        method: 'post',
        payload: postData
      })
      .then((resp)=>{
        EventBus.$emit('reloadEnvs')
        return resp;
      })
    },
    importEnvironmentVars({ commit }, payload) {
      let postData = {
        script: JSON.stringify(payload.script)
      };
      return axiosCall({
        url: `/${payload.transmissionID}/import/${payload.environment.id}?w=true`,
        method: 'post',
        payload: postData
      })
      .then((resp)=>{
        return resp
      })
    },
    deleteEnvironment(_ , payload) {
      return axiosCall({
        url:  `/${payload.transmissionID}/transmission/environment/${payload.envID}`,
        method: 'delete'
      })
      .then((resp)=>{
        return resp
      })
    },
    editEnvironmentName(_ , payload) {
      let patchData = {
        new_value : payload.envName
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/environment/${payload.envId}/name`,
        method: 'patch',
        payload: patchData
      }).then((resp)=>{
        return resp
      })
    },
    cloneEnvironment({ dispatch}, payload) {
      return new Promise((resolve, reject) => {
        dispatch('importEnvironmentVars', { environment: payload.env, script: payload.script, transmissionID: payload.transmissionID }).then((response) => {
          if(resp.status == 200 && !resp.data.error) {
            resolve(response)
          } else {
            throw resp
          }
        }).catch((error) => {
                      if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
          reject(error)
        })
      })
    }
  }
};