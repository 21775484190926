import Vue from 'vue'
// axios
import axios from 'axios'


const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  headers: {
    "x-api-key": "Token 7f926612-2a9e-4b74-b0e5-9b22a1c3525f",
    'Content-Type': 'application/json',
    'Referrer-Policy': 'no-referrer'
  }
})

axiosIns.interceptors.request.use((config) => {
  console.log(config)
  config.headers["Authorization"] = config.headers.session
  let cfg = {
    ...config,
    data: config.data?.data,
    url: `https://api.fique.online/v1${config.headers["x-api-url"]}`
  }
  delete cfg.headers["x-api-url"]
  delete cfg.headers["session"]
  console.log(cfg)
  return cfg
}, (err) => console.log(err));

Vue.prototype.$http = axiosIns

export default axiosIns