import axiosIns from "@/libs/axios.js";
import moment from 'moment'
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import { axiosCall } from "../AxiosCaller";

export default {
  state: {
    user: {
      userId: null,
      username: null,
      email: null,
      ability: null,
      verified_email: false,
      verified_phone: false,
    },
    email_verified: null,
    phone_verified: null,
    username: null,
    password: null,
  },
  getters: {
    getLoggedUser: (state) => {
      return state.user;
    },
    getAbility: (state) => {
      return state.user.ability;
    },
    getVerifiedEmail: (state) => {
      return state.user.verified_email;
    },
    getVerifiedPhone: (state) => {
      return state.user.verified_phone;
    },
    getInformedUsername: (state) => {
      return state.username;
    },
    getInformedEmail: (state) => {
      return state.email;
    },
  },
  mutations: {
    SET_USER_SESSION(state, user) {
      state.user.userId         = user ? user.user.id : null;
      state.user.fullname       = user ? user.user.fullname : null;
      state.user.username       = user ? user.user.username : null;
      state.user.phone          = user ? user.user.phone : null;
      state.user.email          = user ? user.user.email : null;
      state.user.created_at     = user ? moment(user.user.created_at).format("LLL") : null;
      state.user.ability        = user ? user.user.enum_access_level : null;
      state.user.verified_email = user ? user.user.email_verified : null;
      state.user.verified_phone = user ? user.user.phone_verified : null;
      //   state.user.session = user ? user.session : null;
    },
    SET_LOGGED_USER_SESSION(state, user) {
      state.user.userId         = user ? user.id : null;
      state.user.username       = user ? user.username : null;
      state.user.fullname       = user ? user.fullname : null;
      state.user.phone          = user ? user.phone : null;
      state.user.email          = user ? user.email : null;
      state.user.created_at     = user ? moment(user.created_at).format("LLL") : null;
      state.user.ability        = user ? user.enum_access_level : null;
      state.user.verified_email = user ? user.email_verified : null;
      state.user.verified_phone = user ? user.phone_verified : null;
    },
    SET_VERIFIED_EMAIL(state, data) {
      state.user.verified_email = data;
    },
    SET_VERIFIED_PHONE(state, data) {
      state.user.verified_phone = data;
    },
    SET_INFORMED_USERNAME(state, data) {
      state.username = data;
    },
    SET_INFORMED_EMAIL(state, data) {
      state.email = data;
    },
  },
  actions: {
    loginVerification({ commit }, userData) {
      //this request is specifically special because, differently from all the others, it does not send in a SessionKey
      let data = {
        username: userData.username,
        password: userData.password,
      }
      let postData = {
        data: {
          username: userData.username,
          password: userData.password,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/login",
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              // makeToast({
              //   title: i18n.t('common.toast.forbidden.title'),
              //   text: i18n.t('common.toast.forbidden.message'),
              //   variant: "danger",
              //   icon: "SlashIcon",
              // })      
              throw resp        
            } if (resp.status == 200 && !resp.data.error) {
                commit("SET_USER_SESSION", resp.data.data),
                localStorage.setItem("sessionKey", resp.data.data.session),
                resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              // makeToast({
              //   title: i18n.t('common.toast.forbidden.title'),
              //   text: i18n.t('common.toast.forbidden.message'),
              //   variant: "danger",
              //   icon: "SlashIcon",
              // })
            }
            commit("SET_USER_SESSION"       , "");
            commit("SET_INFORMED_USERNAME"  , userData.username);
            commit("SET_INFORMED_EMAIL"     , userData.email);
            reject(error.response?.data || error);
          });
      });
    },
    userRegistration({ commit }, userData) {
      let postData = {
        data: {
          username  : userData.username,
          password  : userData.password,
          email     : userData.email,
          phone     : userData.phone,
          fullname  : userData.fullname,
          access_level: userData.access_level,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit("SET_USER_SESSION", resp.data.data),
              localStorage.setItem("sessionKey", resp.data.data.session),
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            commit("SET_USER_SESSION", "");
            reject(error.response);
          });
      });
    },
    sendEmailConfirmation(_) {
      let config = {
        headers: {
          "x-api-url": "/central/user/solicitation/email",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    emailConfirmation({ commit }, userConfirmationCode) {
      let postData = {
        data: {
          solicitation_code: userConfirmationCode,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/verify_email",
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit("SET_VERIFIED_EMAIL", true)
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            commit("SET_VERIFIED_EMAIL", false);
            reject(error.response.data);
          });
      });
    },
    sendPhoneConfirmation(_) {
      let config = {
        headers: {
          "x-api-url": "/central/user/solicitation/phone",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    phoneConfirmation({ commit }, userConfirmationCode) {
      let postData = {
        data: {
          solicitation_code: userConfirmationCode,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/verify_phone",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit("SET_VERIFIED_PHONE", true);
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            commit("SET_VERIFIED_PHONE", false);
            reject(error.response.data);
          });
      });
    },
    sendAccessPointValidation(_, userConfirmationCode) {
      let postData = {
        data: {
          solicitation_code: userConfirmationCode,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/authorize_login",
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    logout(_) {
      let config = {
        headers: {
          "x-api-url": "/central/user/logout",
          session: `Session ${localStorage.getItem("sessionKey")}`,
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", {}, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    sendResetPasswordSolicitation(_){
      let postData = {
        data: {
          username: this.getters.getLoggedUser.username,
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/solicitation/password",
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .post("/", postData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
    resetPassword(_ , data){
      let patchData = {
        data: {
          "new_password": data.password,
          "solicitation_code": data.code
        },
      };
      let config = {
        headers: {
          "x-api-url": "/central/user/password",
        },
      };
      return new Promise((resolve, reject) => {
       console.trace(); axiosIns
          .patch("/", patchData, config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp);
            } else {
              throw resp
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error.response.data);
          });
      });
    },
  },
};
