export default [
	{
		path: '/transmission',
		name: 'transmission',
		component: () => import('@/views/pages/transmission/Transmission.vue'),
		meta: {
			title: "FiqOn - Transmission",
			pageTitle: 'Transmission',
			breadcrumb: [
				{
					text: 'Transmission',
					active: true,
				},
			],
		},
	},
	{
		path: '/transmission/:transmissionID/transmission-info',
		name: 'transmission-info',
		component: () => import('@/views/pages/transmission/TransmissionInfo.vue'),
		meta: {
			title: "FiqOn - Transmission Info",
			loadTransmissionGroup: true,
			showTerminal: false,
			hideNavbar: true,
			pageTitle: 'Transmission Info',
			refreshInput: true,
			breadcrumb: [
				{
					text: 'Transmission Dashboard',
					to: { name: 'transmission-dashboard' }
				},
				{
					text: 'Transmission Info',
					active: true,
				},
			],
		},
	},
	{
		path: '/transmission/:transmissionID/transmission-dashboard',
		name: 'transmission-dashboard',
		component: () => import('@/views/pages/transmission/Dashboard.vue'),
		meta: {
			title: "FiqOn - Transmission Dashboard",
			pageTitle: 'Transmission Dashboard',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	{
		path: '/transmission/:transmissionID/playground/:routeParam?',
		name: 'playground',
		component: () => import('@/views/pages/middleware/Playground.vue'),
		meta: {
			title: "FiqOn - Middlewares",
			pageTitle: 'Middleware Playground',
			hideNavbar: true,
			loadTransmissionGroup: true,
			showTerminal: false,
      
		},
	},
	{
		path: '/homolog/ifood',
		name: 'playground',
		component: () => import('@/views/pages/middleware/IFoodHomoloag.vue'),
		meta: {
			title: "FiqOn - Homolog Ifood",
			pageTitle: 'Ifood',
			hideNavbar: true,
			loadTransmissionGroup: false,
			showTerminal: false,
      layout: 'full',
		},
	},
	{
		path: '/transmission/:transmissionID/middlewares',
		name: 'middlewares',
		component: () => import('@/views/pages/middleware/MiddlewareManagement/Components/Category/FluxContainer.vue'),
		meta: {
			title: "FiqOn - Middlewares",
			pageTitle: 'Middleware Management',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: 'home'
				},
				{
					text: 'Middleware Management',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: true,
		},
	},
	{
		path: '/transmission/:transmissionID/new-middlewares',
		name: 'new-middlewares',
    redirect: to =>{ return {name: 'middlewares' , params: {transmissionID: to.params.transmissionID}}}
    //this used to be the url to Middleware Management, using a redirect here to keep those links alive 
	},
	{
		path: '/transmission/:transmissionID/logs',
		name: 'transmissionLogs',
		component: () => import('@/layouts/components/Transmission/Dashboard/Log.vue'),
		meta: {
			title: "FiqOn - Logs",
			pageTitle: 'Transmission Logs',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' }
				},
				{
					text: 'Logs',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	{
		path: '/transmission/:transmissionID/integrator',
		name: 'transmission-integrator',
		component: () => import('@/views/pages/transmission/Integrator.vue'),
		meta: {
			title: "FiqOn - Integrator",
			pageTitle: 'Integrator module',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' }
				},
				{
					text: 'Logs',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	{
		path: '/transmission/:transmissionID/transmission-dashboard',
		name: 'transmission-dashboard',
		component: () => import('@/views/pages/transmission/Dashboard.vue'),
		meta: {
			title: "FiqOn - Transmission Dashboard",
			pageTitle: 'Transmission Dashboard',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	//* Scheduler
	{
		path: '/transmission/:transmissionID/scheduler',
		name: 'transmission-scheduler',
		component: () => import('@/views/pages/transmission/scheduler/Scheduler.vue'),
		meta: {
			title: "FiqOn - Transmission Scheduler",
			pageTitle: 'Transmission Scheduler',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' },
				},
				{
					text: 'Scheduler',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	{
		path: '/transmission/:transmissionID/scheduler/:schedulerID',
		name: 'transmission-scheduler-view',
		component: () => import('@/views/pages/transmission/scheduler/SchedulerView.vue'),
		meta: {
			title: "FiqOn - Transmission Scheduler View",
			pageTitle: 'Scheduler View',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' },
				},
				{
					text: 'Scheduler',
					to: { name: 'transmission-scheduler' },
				},
				{
					text: 'Scheduler View',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
	{
		path: '/transmission/:transmissionID/webhooks',
		name: 'transmission-webhooks',
		component: () => import('@/views/pages/transmission/Webhooks.vue'),
		meta: {
			title: "FiqOn - Transmission Webhooks",
			pageTitle: 'Webhooks View',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' },
				},
				{
					text: 'Webhooks View',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
  {
		path: '/transmission/:transmissionID/webhook-info/:webhookID',
		name: 'webhook-view',
		component: () => import('@/views/pages/transmission/WebhookInfo.vue'),
		meta: {
			title: "FiqOn - Transmission Webhook",
			pageTitle: 'Webhook Info',
			hideNavbar: true,
			breadcrumb: [
				{
					text: 'Home',
					to: { name: 'home' }
				},
				{
					text: 'Transmission',
					to: { name: 'transmission-dashboard' },
				},
				{
					text: 'Webhooks',
					to: { name: 'transmission-webhooks' },
				},
				{
					text: 'Webhook Info',
					active: true,
				},
			],
			loadTransmissionGroup: true,
			showTerminal: false,
		},
	},
]