import axiosIns from "@/libs/axios";
import * as toast from '@/custom/class/FunctionClasses/CommonToasts.js'


export function axiosCall({
  payload,
  url,
  method,
  queries,
  loadingOnCursor = false,
  noBody=false,
  treatResponse = resp => resp.data.data
}){
  
  return new Promise((resolve, reject) => {
    if (queries){
      //URL Query Setup
      let params =  new URLSearchParams(queries)
      url = url.concat(params.toString())
    }

    let config = {
      headers: {
        "x-api-url": url,
        "session": `Session ${localStorage.getItem("sessionKey")}`,
      },
    };

    if (loadingOnCursor){
      //sets loading cursor if set to do so 
      document.body.setAttribute('style', 'cursor: wait !important');
    }

    if (!['get','delete','post','put','patch'].includes(method)){ //throws an error if using improper method
      reject('error: improper request method')
    }
    
    let p = { data: payload }
    if (noBody){
      p = undefined
    }
    let axiosParams = ["/", p ,config]
    if (!payload){
      axiosParams = ["/",config]
    }

    axiosIns[method](...axiosParams)
    .then((resp) => {
      if (resp.status == 403){
        toast.commonToast('403')
      } 
      if (resp.status == 200 && !resp.data.error) {
        
        let r = treatResponse(resp)
        resolve(r)

      } else {
        throw resp;
      }
    })
    .catch((error) => {
      if (error?.response?.status == 403){
        toast.commonToast('403')
      }
      reject(error);
    })
    .finally(()=>{
      if (loadingOnCursor){
        document.body.setAttribute('style', 'cursor: undefined');
      }
    })
  })
}

