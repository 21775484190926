  import axiosIns from "@/libs/axios";
import { makeToast } from "@/layouts/components/Popups";
import i18n from '@/libs/i18n'
import {axiosCall} from '@/store/AxiosCaller.js'

export default {
  state: {
    currentMiddleware: {
      id: undefined,
      name: undefined,
      event: undefined,
      service: undefined,
      webhook: undefined,
      enum_direction: {
        id: undefined,
        label: undefined,
      },
      enum_type: {
        id: undefined,
        label: undefined,
      },
      associated_id: undefined,
      execution_order: undefined,
      agents: [],
      created_at: undefined,
      updated_at: undefined,
    },
    middleware_list: {},
    allowMiddlewareEditing: true,
    selectedMiddlewares: {
      depth: null,
      data: [],
      selectedIDs: []
    },
    copiedMiddlewares: undefined,
    reorderingMiddlewares: {
      depth: null,
      data: [], //
      selectedIDs: []
    },
    rollbackUpdater: false, //this value is watched on SoftRollbackButton.vue and every time it changes the current Flux is saved Locally
  },
  getters: {
    getCurrentMiddleware: (state) => {
      return state.currentMiddleware;
    },
    getMiddlewares: (state) => {
      return state.middleware_list;
    },
    rearrangementOverlayOff: (state) =>{
      return state.allowMiddlewareEditing
    },
    getSelectedMiddlewares: (state) =>{
      return state.selectedMiddlewares
    },
    getCopiedMiddlewares: (state) =>{
      return state.copiedMiddlewares
    },
    getReorderingMiddlewares:(state) => {
      return state.reorderingMiddlewares
    },
    getRollbackUpdater: (state)=> {
      return state.rollbackUpdater
    }
  },
  mutations: {
    SET_CURRENT_MIDDLEWARE(state, middleware) {
      Object.keys(state.currentMiddleware).forEach(el => {
        state.currentMiddleware[el] = middleware[el]
      });
    },
    SET_MIDDLEWARE_LIST(state, payload) {
      state.middleware_list[payload.transmissionID] = payload.data;
    },
    SET_ALLOW_MIDDLEWARE_EDITING(state, payload){
      state.allowMiddlewareEditing = payload
    },
    CLEAR_MIDDLEWARE_SELECTION(state){
      state.selectedMiddlewares = {
        depth: null,
        data: [],
        selectedIDs: []
      }
    }
  },
  actions: {
    getMiddlewareByID({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware/${payload.id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit("SET_CURRENT_MIDDLEWARE", resp.data.data);

              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    bulkDeleteMiddlewares({ commit }, payload) {

      let url =   `/${payload.transmissionID}/transmission/middleware/bulk?ids=[${payload.middlewares}]`
 
      return axiosCall({
        method: 'delete',
        url: url,
        loadingOnCursor: true
      })
    },
    deleteMiddleware(_, payload) {

      return axiosCall({
        url: `/${payload.transmissionID}/transmission/middleware/${payload.id}`,
        method: 'delete'
      }).then((resp)=>{
        return resp
      })
    },
    patchMiddlewareName(_, payload) {
      let data = {
        new_value: payload.name
      }
      return axiosCall({
        url:  `/${payload.transmissionID}/transmission/middleware/${payload.id}/name`,
        method: 'patch',
        payload: data,
        treatResponse: resp => resp.data
      })
    },
    getAllMiddlewares({ commit, getters }, payload) {
      return new Promise((resolve, reject) => {
        if(getters.getMiddlewares[payload.transmissionID]) {
          resolve(getters.getMiddlewares[payload.transmissionID]);
        }

        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              commit('SET_MIDDLEWARE_LIST', {
                transmissionID: payload.transmissionID,
                data: resp.data.data
              });

              resolve(resp.data.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getAllMiddlewareByCategory(_, payload) {
      let newPayload = {
        "event_id": payload.event_id || null,
        "service_id": payload.service_id || null,
        "webhook_id": payload.webhook_id || null,
        "enum_direction_id": payload.direction_id,
      }
      let url =  `/${payload.transmissionID}/transmission/middleware/list/all`
 
      return axiosCall({
        method: 'post',
        url: url,
        payload: newPayload,
      })
    },
    createMiddleware({dispatch}, payload,) {

      let url = `/${payload.transmissionID}/transmission/middleware`
      
      delete payload.transmissionID

      return axiosCall({
        method: 'post',
        url: url,
        payload: payload,
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },    
    bulkAddMiddlewares({commit, state, dispatch}, payload) {
      let url = `/${payload.transmissionID}/transmission/middleware/bulk`
      
      delete payload.transmissionID

      return axiosCall({
        method: 'post',
        url: url,
        payload: payload,
        loadingOnCursor: true,
        treatResponse: resp => resp.data
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },    
    reorderMiddlewares({commit, state, dispatch}, payload) {
      let url = `/${payload.transmissionID}/transmission/middleware/execution_order`;
      let newPayload = {
        previous: parseInt(payload.previous),
        first: parseInt(payload.first),
        last: parseInt(payload.last),
      }
      return axiosCall({
        method: 'patch',
        url: url,
        payload: newPayload,
        loadingOnCursor: true,
        treatResponse: resp => resp.data
      }).then((resp)=>{
        dispatch('updateLocalRollback')
        return resp
      })
    },
    patchMiddleware(_, payload) {
      let transmissionGroup = payload.transmissionID
      let data ={
        new_value: payload.new_value
      }
      return axiosCall({
        url: `/${transmissionGroup}/transmission/middleware/${payload.id}/${payload.field}`,
        method: 'patch',
        payload: data
      })
    },
    pathMiddlewareActive({commit, state}, payload){
      const data = {
        new_value: payload.value
      }
      return axiosCall({
        url: `/${payload.transmissionID}/transmission/middleware/${payload.middlewareID}/is_active`,
        method: "patch",
        payload: data,
      })
    },
    userSelectMiddleware({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        if (state.selectedMiddlewares.selectedIDs.includes(payload.data.id)){ //if (Middleware Already Selected)
          const firstSelected = state.selectedMiddlewares.data[0];
          const lastSelected = state.selectedMiddlewares.data[state.selectedMiddlewares.data.length - 1];
          if (firstSelected.id == payload.data.id){
            state.selectedMiddlewares.data.shift() //removes first
          } else if (lastSelected.id == payload.data.id){
            state.selectedMiddlewares.data.pop() //removes last
          }
          state.selectedMiddlewares.data
          
        } else {
          if (typeof state.selectedMiddlewares.depth == 'number' && (state.selectedMiddlewares.depth != payload.depth)){
            makeToast({
              title: "Can't do that",
              text: "you can only select Middlewares on a single Level",
              variant: "warning",
              icon: "SlashIcon",
            })
            return
          }
        
          if (state.selectedMiddlewares.data.length || payload.exception){

            let previousID = state.selectedMiddlewares.data[0].previous_id
            let nextID = state.selectedMiddlewares.data[state.selectedMiddlewares.data.length-1].next_id
            
            if (previousID == payload.data.id || payload.exception == 'previous'){ //adds first
              state.selectedMiddlewares.data.unshift(payload.data)
              
            } else if (nextID == payload.data.id || payload.exception == 'next'){ //adds last
              state.selectedMiddlewares.data.push(payload.data)
              
            } 
            
            // else if (payload.shiftPressed){ // multi-select
            //   console.log("selecting mulipce")
            // }
            
            else {
              makeToast({
                title: "Can't do that",
                text: "you can only select Middlewares Sequentially",
                variant: "warning",
                icon: "SlashIcon",
              })
              return
            }       
          } else {
            state.selectedMiddlewares.data.push(payload.data)
          }
          
          state.selectedMiddlewares.depth = payload.depth
        } 
        
        state.selectedMiddlewares.selectedIDs = []
        function getIdInMiddleware(s){
          s.forEach(mid=>{
            state.selectedMiddlewares.selectedIDs.push(mid.id)
            if (mid.middlewares.length){
              getIdInMiddleware(mid.middlewares)
            }
          })
        }

        getIdInMiddleware(state.selectedMiddlewares.data)        
      })
    },
    selectAllMiddlewares({commit, state}, payload) {
      return new Promise((resolve, reject) => {
          
        state.selectedMiddlewares.data = (payload)
        

        state.selectedMiddlewares.depth = 0
        
        state.selectedMiddlewares.selectedIDs = []

        function getIdInMiddleware(s){
          s.forEach(mid=>{
            state.selectedMiddlewares.selectedIDs.push(mid.id)
            if (mid.middlewares.length){
              getIdInMiddleware(mid.middlewares)
            }
          })
        }
        getIdInMiddleware(state.selectedMiddlewares.data)
     })
    },
    userCopyMiddlewares({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        state.copiedMiddlewares = payload
     })
    },
    userMarkSelectedMiddlewaresAsReorder({commit, state},) {
      return new Promise((resolve, reject) => {
        state.reorderingMiddlewares = structuredClone(state.selectedMiddlewares)
      })
    },
    userClearReorderMiddlewares({commit, state}) {
      return new Promise((resolve, reject) => {
        state.reorderingMiddlewares = {
          depth: null,
          data: [],
          selectedIDs: []
        }
     })
    },
    clearMiddlewareSelection({commit, state}, ) {
      return new Promise((resolve, reject) => {
        commit('CLEAR_MIDDLEWARE_SELECTION')
    })
    },
    setAllowMiddlewareEditing({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ALLOW_MIDDLEWARE_EDITING', payload)
      })
    },
    updateLocalRollback({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        state.rollbackUpdater = !state.rollbackUpdater
      })
    },

    

    
    // =========================== PROBABLY LEGACY ===============================
    getAllEventMiddlewares(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware/category/event?direction=${payload.direction_id}&event_id=${payload.event_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getAllServiceMiddlewares(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware/category/service?direction=${payload.direction_id}&service_id=${payload.service_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getAllServiceEventMiddlewares(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware/category/event_service?direction=${payload.direction_id}&event_id=${payload.event_id}&service_id=${payload.service_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    getAllWebhookMiddlewares(_, payload) {
      return new Promise((resolve, reject) => {
        let transmissionGroup = payload.transmissionID

        if (!transmissionGroup) {
          reject({ "message": "common.error.transmission_not_selected" })
        }

        let config = {
          headers: {
            "x-api-url": `/${transmissionGroup}/transmission/middleware/category/webhook?direction=${payload.direction_id}&webhook_id=${payload.webhook_id}`,
            session: `Session ${localStorage.getItem("sessionKey")}`,
          },
        };

       console.trace(); axiosIns
          .get("/", config)
          .then((resp) => {
            if (resp.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })              
            } 
            if (resp.status == 200 && !resp.data.error) {
              resolve(resp.data);
            } else {
              throw resp;
            }
          })
          .catch((error) => {
            if (error?.response?.status == 403){
              makeToast({
                title: i18n.t('common.toast.forbidden.title'),
                text: i18n.t('common.toast.forbidden.message'),
                variant: "danger",
                icon: "SlashIcon",
              })
            }
            reject(error);
          });
      });
    },
    // =========================== PROBABLY LEGACY ===============================
  }
}


// antes isso tinhha 975 linhas