<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <b-overlay
      :show="loading"
      no-wrap
      variant="black"
      class="on-top position-fixed"
      spinner-variant="primary"
      spinner-type="grow"
    />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { BOverlay} from "bootstrap-vue";
import EventBus from "@/custom/eventBus";
import { useWindowSize, useCssVar } from '@vueuse/core'
import { BCollapse } from 'bootstrap-vue';

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    BOverlay,
    BCollapse,
  },
  data() {
    return {
      loading: false,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  mounted() {
    EventBus.$on("startLoading", () => {
      this.loading = true;
    });
    EventBus.$on("stopLoading", () => {
      this.loading = false;
    });

    //Randomized LocalStorage Clearer:
    //if left untouched LocalStorage may keep accumulating endlessly until it maxes out
    let r = Math.floor(Math.random() * 70);
    let d = new Date().getDay()

    //will only run on mondays with 1 to 70 chance every reload
    if (d == 1 && r == 1){
      let fieldsToKeep = {
        'selectedOrganization': null, 
        'sessionKey' : null
      }
      
      Object.keys(fieldsToKeep).forEach(field => {
        let key = localStorage.getItem(field);
        fieldsToKeep[field] = key
      });
      
      localStorage.clear()

      Object.keys(fieldsToKeep).forEach(field => {
        let value = fieldsToKeep[field];
        localStorage.setItem(field , value)
      });
    }

  },
  beforeDestroy() {
    EventBus.$off("startLoading");
    EventBus.$off("stopLoading");
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
.wizard-icon {
  color: #fff !important;
}
.on-top {
  z-index: 9999 !important;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    width:  0;
  }
}

.justify-content-evenly{
  justify-content: space-evenly;
}
.w-fit-content{
  width: fit-content;
}

.standard-box-shadow{
 box-shadow: 5px 5px  0px rgba(0, 0, 0, 0.5); 
}
</style>


<style lang="scss">
//=============================== SCROLLBAR COLOR CUSTOMIZATION ===============================
  //this is particular to Chrome based browsers 

  *::-webkit-scrollbar {
    width: 6px; /* Mostly for vertical scrollbars */
    height: 6px; /* Mostly for horizontal scrollbars */
  }
  *::-webkit-scrollbar-thumb { /* Foreground */
    background-color: rgba(238, 238, 238, 0.4);
    border-radius: 5px;
    &:hover{
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
  *::-webkit-scrollbar-track { /* Background */
    background: rgba(15, 20, 34, 0.5);
  }
//=============================== SCROLLBAR COLOR CUSTOMIZATION ===============================
</style>